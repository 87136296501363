.App {
  margin-top: 3mm;
  text-align: center;
}

.App-logo {
  width: 6em;
  height: 6em;
  border-radius: 5em;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 20vh;
  align-items: center;
  justify-content: center;
  /* display: flex; */
  /* flex-direction: row; */
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.backg{
  background-image: url(../src/assets/background.svg);
}

.footer {
  position: absolute;
  width: 100%;
  bottom: 0%;
  width: 100%;
  height: 40px;
}

.body {
  position: relative;
  min-height: 100vh;
  padding-bottom: 40px;
  
}